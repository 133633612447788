<template lang="pug">
modal(
  v-model="valueComputed"
  :primaryButton="primaryButton"
  :secondaryButton="secondaryButton"
  @input="closePopup"
  @submit="confirm"
)
  template(v-slot:title) {{ title }}
  template(v-slot:body)
    span(v-html="message")
</template>

<script>
export default {
  name: 'GernalPopup',
  components: {
    modal: () => import('@/components/modal'),
  },
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    title: {
      type: String,
      default: '',
    },
    message: {
      type: String,
      default: '',
    },
    showOnce: {
      type: Boolean,
      default: false,
    },
    popupName: {
      type: String,
      default: '',
    },
    primaryButton: {
      type: [Object, Boolean],
      default: () => ({
        class: 'primary',
        text: 'Doorgaan',
      }),
    },
    secondaryButton: {
      type: [Object, Boolean],
      default: () => ({
        class: 'plain',
        text: 'Annuleren',
      }),
    },
  },
  data() {
    return {
      hide: false,
    };
  },
  computed: {
    valueComputed: {
      get() {
        return this.value;
      },
      set(value) {
        this.$emit('input', value);
      },
    },
  },
  methods: {
    confirm() {
      this.$emit('confirm');
      this.closePopup();
    },
    closePopup() {
      this.$emit('input', false);
      if (this.showOnce) {
        localStorage.setItem(this.popupName, 'true');
      }
    },
  },
};
</script>
<style lang="scss">
</style>
